import { create } from 'zustand'

interface ModalState {
   isAreYouAnAgentModalOpen: boolean
   isUserAgent: boolean
   openAreYouAnAgentModal: () => void
   closeAreYouAnAgentModal: () => void
   setIsUserAgent: (isAgent: boolean) => void
}

export const useAreYouAnAgentModalStore = create<ModalState>((set) => ({
   isAreYouAnAgentModalOpen: false,
   isUserAgent: false,
   openAreYouAnAgentModal: () => set({ isAreYouAnAgentModalOpen: true }),
   closeAreYouAnAgentModal: () => set({ isAreYouAnAgentModalOpen: false }),
   setIsUserAgent: (isAgent: boolean) => set({ isUserAgent: isAgent }), // Add this line
}))
