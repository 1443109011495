import React, { forwardRef } from 'react'
import { Form, Input, InputRef } from 'antd'

type TextType = {
   name?: string
   className?: string
   step?: string
   isRequired?: boolean
   placeholder?: string
   pattern?: string
   disabled?: boolean
   errorMessage?: string
   type?: string
   formItemClassName?: string
   label?: string
   optional?: boolean
   validatePostalCode?: boolean
   tooltip?: string
   validatePhoneNumber?: boolean
   validateUrl?: boolean
   maxLength?: number
   validateEmail?: boolean
   validatePassword?: boolean
   dependencies?: string
   onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const TextField = forwardRef<InputRef, TextType>(
   (
      {
         name,
         className,
         formItemClassName,
         isRequired,
         placeholder,
         errorMessage,
         type = 'text',
         label,
         optional,
         tooltip,
         validatePostalCode,
         validatePhoneNumber,
         validateUrl,
         validatePassword,
         validateEmail,
         maxLength = undefined,
         dependencies,
         onChange,
      },
      ref
   ) => {
      function regPattern() {
         if (validatePostalCode) {
            maxLength = 5
            return new RegExp(/^\d{5}$/)
         } else if (validatePhoneNumber) {
            return new RegExp(
               /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/
            )
         } else if (validateUrl) {
            return new RegExp(/^https?:\/\/[^\s/$.?#].[^\s]*$/)
         } else if (validateEmail) {
            return new RegExp(
               /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            )
         } else if (validatePassword) {
            return new RegExp(
               /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/
            )
         } else {
            return undefined
         }
      }

      return (
         <Form.Item
            name={name}
            label={label}
            className={`${formItemClassName} relative ${
               optional && 'show-optional'
            }`}
            tooltip={tooltip}
            dependencies={dependencies ? [dependencies] : undefined}
            rules={[
               {
                  required: isRequired,
                  message: errorMessage,
                  pattern: regPattern(),
               },
            ]}
         >
            <Input
               ref={ref}
               autoFocus
               type={type}
               maxLength={maxLength}
               onChange={onChange}
               className={`${className} ${
                  optional && 'pr-20'
               } w-full placeholder:text-[#818181] px-[22px] rounded-xl !border-white/10 focus:border-white text-white`}
               placeholder={placeholder}
            />
         </Form.Item>
      )
   }
)
