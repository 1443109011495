import { create } from 'zustand'

interface ModalState {
   isUpdateUserDetailsModalOpen: boolean
   openUpdateUserDetailsModal: () => void
   closeUpdateUserDetailsModal: () => void
}

export const useUpdateUserDetailsModalStore = create<ModalState>((set) => ({
   isUpdateUserDetailsModalOpen: false,
   openUpdateUserDetailsModal: () =>
      set({ isUpdateUserDetailsModalOpen: true }),
   closeUpdateUserDetailsModal: () =>
      set({ isUpdateUserDetailsModalOpen: false }),
}))
