import axios from 'axios'

import { IUser } from '@forwardflow/types/user'
import LocalStorage from '@forwardflow/services/LocalStorage'

import {
   createContext,
   JSX,
   useContext,
   useEffect,
   useMemo,
   useState,
} from 'react'
import useAuthUser from '@forwardflow/hooks/customHooks/useAuthUser'

const AUTH_SESSION_KEYS = ['user', 'authenticated']

export type AuthProps = {
   user: IUser | null
   authenticated: boolean
   loading: boolean
}

export interface IAuthContext<T> {
   auth: T
   setAuth: React.Dispatch<React.SetStateAction<T>>
}

type AuthProviderProps = {
   children: React.ReactNode
}

const AuthContext = createContext<IAuthContext<AuthProps>>({
   auth: { user: null, authenticated: false, loading: false },
   setAuth: () => {},
})

export default function AuthProvider({
   children,
}: AuthProviderProps): JSX.Element {
   const { data: user, mutate, isLoading, error, isValidating } = useAuthUser()

   const [auth, setAuth] = useState<AuthProps>(() => ({
      user: LocalStorage.get('user') as IUser | null,
      authenticated: LocalStorage.get('authenticated') === 'true',
      loading: isLoading,
   }))

   useEffect(
      () => setAuth((current) => ({ ...current, loading: isLoading })),
      [isLoading]
   )

   useEffect(() => {
      if (auth?.authenticated === true) {
         mutate()
      }
   }, [auth?.authenticated, mutate])

   useEffect(() => {
      if (isValidating && axios.isAxiosError(error)) {
         if (error?.response?.status === 401) {
            AUTH_SESSION_KEYS.forEach((key) => LocalStorage.delete(key))
            setAuth((current) => ({
               ...current,
               user: null,
               authenticated: false,
               organizationId: null,
            }))
            if (window.location.pathname !== '/') {
               window.location.href = '/verify-email'
            }
         }
      }
   }, [error, isValidating])

   useEffect(() => {
      if (user) {
         setAuth((current) => ({
            ...current,
            user: user as IUser,
            authenticated: true,
         }))

         LocalStorage.put('user', user)
         LocalStorage.put('authenticated', true)
      }
   }, [user])

   const value = useMemo(() => ({ auth, setAuth }), [auth])

   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
   const context = useContext(AuthContext)
   if (!context) {
      throw new Error('useAuth must be used within an AuthProvider')
   }
   return context
}

export { useAuth, AuthContext }
